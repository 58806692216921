.preview__link:link, .preview__link:visited {
    align-items: center;
    padding: 1.5rem 3.25rem;
    text-decoration: none;
    transition: all .3s;
    display: flex;
  }
  
  .preview__link:hover {
    background-color: #f9f5f3;
    transform: translateY(-2px);
  }
  
  .preview__link--active {
    background-color: #f9f5f3;
  }
  
  .preview__fig {
    height: 5.8rem;
    backface-visibility: hidden;
    border-radius: 50%;
    flex: 0 0 5.8rem;
    margin-right: 2rem;
    position: relative;
    overflow: hidden;
  }
  
  .preview__fig:before {
    content: "";
    height: 100%;
    width: 100%;
    opacity: .4;
    background-image: linear-gradient(to bottom right, #bf2626, #ee9700);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .preview__fig img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .3s;
    display: block;
  }
  
  .preview__data {
    width: 100%;
    grid-template-columns: 1fr 2rem;
    align-items: center;
    row-gap: .1rem;
    display: grid;
  }
  
  .preview__title {
    color: #000;
    text-transform: uppercase;
    text-overflow: ellipsis;
    max-width: 25rem;
    white-space: nowrap;
    grid-column: 1 / -1;
    font-size: 1.45rem;
    font-weight: 600;
    overflow: hidden;
  }
  
  .preview__publisher {
    color: #514c4b;
    text-transform: uppercase;
    font-size: 1.15rem;
    font-weight: 600;
  }
  
  .preview__user-generated {
    height: 2rem;
    width: 2rem;
    background-color: #eeeae8;
    border-radius: 10rem;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 1.75rem;
    display: flex;
  }
  
  .preview__user-generated svg {
    height: 1.2rem;
    width: 1.2rem;
    fill: #000;
  }
  
  .search-results {
    flex-direction: column;
    padding: 3rem 0;
    display: flex;
  }
  
  .results {
    margin-bottom: 2rem;
    list-style: none;
  }
  
  .pagination {
    margin-top: auto;
    padding: 0 3.5rem;
  }
  
  .pagination:after {
    content: "";
    clear: both;
    display: table;
  }
  
  .pagination__btn--prev {
    float: left;
  }
  
  .pagination__btn--next {
    float: right;
  }
